import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import PostPreview from '../components/post-preview'
import PageSelector from '../components/page-selector'

const Tags = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  const { tag, currentPage, numPages } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={`Posts with tag ${tag}`}
        description="Federico Gambarino's Blog"
      />
      <h3>
        Posts with tag: <em>{`${tag}`}</em>
      </h3>
      {posts.map(({ node }) => {
        const { slug, frontmatter, excerpt } = node
        const title = node.frontmatter.title || node.slug
        return (
          <PostPreview
            key={slug}
            slug={`/${slug}`}
            title={title}
            date={frontmatter.date}
            tags={frontmatter.tags}
            description={frontmatter.description || excerpt}
          ></PostPreview>
        )
      })}

      <PageSelector
        currentPage={currentPage}
        numPages={numPages}
        location={location}
      />
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
  }
`
